<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        <v-icon color="primary" left>mdi-apple-airplay</v-icon>
        Auditoria
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-menu
              v-model="dataInicioDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dataInicioFormatted"
                  label="Data inicio"
                  dense
                  clearable
                  @click:clear="dataInicio = today"
                  readonly
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dataInicio"
                @input="dataInicioDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="dataFimDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dataFimFormatted"
                  label="Data Fim"
                  dense
                  clearable
                  @click:clear="dataFim = ''"
                  readonly
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dataFim"
                @input="dataFimDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
          v-if="audits"
          :headers="headers"
          :items="audits"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToAudit"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{ item.updated_at | dateFormat("dd/MM/yyyy") }}
          </template>
        </v-data-table>
      </v-card-text>
      <!-- -->
      <v-dialog v-model="dialog" scrollable width="700">
        <v-card>
          <v-card-title class="accent text-h5 font-weight-bold pa-4">
            <v-icon color="primary" left>mdi-apple-airplay</v-icon>
            Auditoria
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon size="30">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-row>
              <v-col v-if="audit.user_id" cols="12" sm="6">
                Usuario ID
                <v-sheet class="pa-2" color="accent">
                  {{ audit.user_id }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                IP Usuario
                <v-sheet class="pa-2" color="accent">
                  {{ audit.ip_address }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.user_id" cols="12" sm="6">
                Tipo Evento
                <v-sheet class="pa-2" color="accent">
                  {{ audit.event }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                Tipo
                <v-sheet class="pa-2" color="accent">
                  {{ audit.auditable_type }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12">
                URL Usado
                <v-sheet class="pa-2" color="accent">
                  {{ audit.url }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.old_values" cols="12">
                Valor Antigo
                <v-sheet class="pa-2" color="accent">
                  <vue-json-pretty
                    :data="audit.old_values"
                    path="res"
                    :deep="3"
                    :collapsed-on-click-brackets="true"
                  >
                  </vue-json-pretty>
                </v-sheet>
              </v-col>

              <v-col v-if="audit.new_values" cols="12">
                Valor Novo
                <v-sheet class="pa-2" color="accent">
                  <vue-json-pretty
                    :data="audit.new_values"
                    path="res"
                    :deep="3"
                    :collapsed-on-click-brackets="true"
                  >
                  </vue-json-pretty>
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                Criado
                <v-sheet class="pa-2" color="accent">
                  {{ audit.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                Atualizado
                <v-sheet class="pa-2" color="accent">
                  {{ audit.updated_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { getAudits } from "@/api/admin/audits.js";
import { format, parseISO, subDays } from "date-fns";
export default {
  name: "ListaAudits",

  data() {
    return {
      loading: true,
      dialog: false,
      audits: [],
      audit: {},
      today: format(new Date(), "yyyy-MM-dd"),
      dataInicio: format(subDays(new Date(), 7), "yyyy-MM-dd"),
      dataFim: "",
      dataInicioDatePicker: false,
      dataFimDatePicker: false,
    };
  },
  components: {
    VueJsonPretty: () => import("vue-json-pretty"),
  },

  watch: {
    dataInicio() {
      this.fetchAudits();
    },
    dataFim() {
      this.fetchAudits();
    },
  },

  computed: {
    headers() {
      return [
        
        {
          text: "ID usuario",
          value: "user_id",
        },
        {
          text: "Evento",
          value: "event",
        },
        {
          text: "Tipo Auditable",
          value: "auditable_type",
        },
        {
          text: "IP",
          value: "ip_address",
        },
        {
          text: "CRIADO",
          value: "created_at",
        },
        {
          text: "UPDATE",
          value: "updated_at",
        },
      ];
    },
    url() {
      let queryString = "?";
      if (this.dataInicio) {
        queryString += `&dataInicio=${this.dataInicio}`;
      }
      if (this.dataFim) {
        queryString += `&dataFim=${this.dataFim}`;
      }
      return queryString;
    },
    dataInicioFormatted() {
      return this.dataInicio
        ? format(parseISO(this.dataInicio), "dd/MM/yyyy")
        : "";
    },
    dataFimFormatted() {
      return this.dataFim
        ? format(parseISO(this.dataFim), "dd/MM/yyyy")
        : format(parseISO(this.today), "dd/MM/yyyy");
    },
  },

  methods: {
    goToAudit(item) {
      this.audit = { ...item };
      this.audit.old_values = JSON.stringify(item.old_values);
      this.audit.new_values = JSON.stringify(item.new_values);
      this.dialog = true;
    },

    fetchAudits() {
      this.loading = true;

      return getAudits(this.url)
        .then((response) => {
          this.audits = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchAudits();
    this.loading = false;
  },
};
</script>

<style></style>
